import React from "react";
import {
  SocialContainer,
  LinkedInIcon,
  EmailIcon,
  GitHubIcon,
  TwitterIcon,
  InstagramIcon,
  ResumeIcon,
  IconLink,
} from "./StyledSocial";

const Socials = () => {
  return (
    <SocialContainer>
      <IconLink href="mailto:ryan_kim@brown.edu">
        <EmailIcon />
      </IconLink>
      <IconLink
        href="https://www.linkedin.com/in/ryanhkim/"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedInIcon />
      </IconLink>
      <IconLink
        href="https://github.com/ryanhkim"
        target="_blank"
        rel="noreferrer"
      >
        <GitHubIcon />
      </IconLink>
      <IconLink
        href="https://twitter.com/_ryanhkim"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </IconLink>
      <IconLink
        href="https://www.instagram.com/ry_ankim/"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </IconLink>
      <IconLink
        href={process.env.PUBLIC_URL + "/resume.pdf"}
        target="_blank"
        rel="noreferrer"
      >
        <ResumeIcon />
      </IconLink>
    </SocialContainer>
  );
};

export default Socials;
