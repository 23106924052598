import styled, { css } from "styled-components";
import {
  LinkedIn,
  Twitter,
  GitHub,
  Description,
  Email,
  Instagram,
} from "@material-ui/icons";
import { primary, secondary } from "../../utils/theme";

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 576px) {
    justify-content: start;
  }
`;

export const IconLink = styled.a`
  margin-right: 10px;
`;

const iconStyles = css`
  color: ${secondary};
  :hover {
    color: ${primary};
  }
  font-size: 1.5rem;
`;

export const LinkedInIcon = styled(LinkedIn)`
  ${iconStyles}
`;

export const TwitterIcon = styled(Twitter)`
  ${iconStyles}
`;

export const GitHubIcon = styled(GitHub)`
  ${iconStyles}
`;

export const ResumeIcon = styled(Description)`
  ${iconStyles}
`;

export const InstagramIcon = styled(Instagram)`
  ${iconStyles}
`;

export const EmailIcon = styled(Email)`
  ${iconStyles}
`;
