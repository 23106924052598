import React from "react";
import {
  AvatarContainer,
  HomeContainer,
  Avatar,
  IntroductionText,
} from "./StyledHome";
import Social from "../../shared/Social/Social";
import { ColContainer, HeaderText } from "../../utils/theme";
import avatar from "../../assets/images/avatar.png";

const Home = () => {
  return (
    <HomeContainer>
      <AvatarContainer>
        <Avatar src={avatar} />
      </AvatarContainer>
      <ColContainer>
        <HeaderText>Hello! I'm Ryan!</HeaderText>
        <IntroductionText>
          I’m currently a senior at Brown studying computer science + urban
          studies!
        </IntroductionText>
        <Social />
      </ColContainer>
    </HomeContainer>
  );
};

export default Home;
