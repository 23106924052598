import styled from "styled-components";

export const HomeContainer = styled.div`
  margin-top: 7.5vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    margin-top: 10vh;
    flex-direction: row;
  }
  @media (min-width: 992px) {
    margin-top: 20vh;
  }
  @media (min-width: 1920px) {
    margin-top: 10vh;
  }
  @media (min-width: 3840px) {
    margin-top: 30vh;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
    justify-content: start;
    margin-left: -53px;
  }
`;

export const Avatar = styled.img`
  height: 200px;
  width: 210px;
  @media (min-width: 992px) {
    height: 300px;
    width: 315px;
  }
  @media (min-width: 1920px) {
    height: 400px;
    width: 420px;
  }

  @media (min-width: 3840px) {
    height: 600px;
    width: 630px;
  }
`;

export const IntroductionText = styled.h2`
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  text-align: center;
  @media (min-width: 576px) {
    text-align: start;
  }
`;
