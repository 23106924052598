import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { ContentContainer, ColContainer } from "./utils/theme";
// import Header from "./shared/Header/Header";
// import HashLinkPage from "./HashLinkPage";
import Home from "./pages/Home/Home";
// import { HashLink, NavHashLink } from 'react-router-hash-link';

const App = () => {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <ContentContainer>
        <ColContainer>
          {/* <Switch>
            <Route exact path="/experiences" component={Experiences} />
            <Route exact path="/writing" component={Writing} />
            <Route
              exact
              path="/post/:id"
              render={(props) => <Post id={props.match.params.id} />}
            />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/about" component={About} />
            <Route component={Home} />
          </Switch> */}
          <Switch>
            <Route component={Home} />
          </Switch>
        </ColContainer>
      </ContentContainer>
    </BrowserRouter>
  );
};

export default App;
