import styled from "styled-components";

export const primary = "#796CCB";

export const secondary = "#BDBDBD";

export const primaryLight = "rgba(121, 108, 203, 0.35)";

export const ContentContainer = styled.div`
  height: 87.5vh;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 576px) {
    margin-left: 150px;
    margin-right: 150px;
  }
  @media (min-width: 992px) {
    margin-left: 300px;
    margin-right: 300px;
  }
  @media (min-width: 1920px) {
    margin-left: 600px;
    margin-right: 600px;
  }
  @media (min-width: 3840px) {
    margin-left: 1000px;
    margin-right: 1000px;
  }
`;

export const PageContainer = styled.div``;

export const Text = styled.p`
  font-size: 1rem;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: black;
  :hover {
    background-color: ${primaryLight};
  }
`;

export const HeaderText = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  text-align: center;
  @media (min-width: 576px) {
    text-align: start;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
